.shepherdGuideTour {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  width: 296px;
  height: fit-content;
}

.shepherd-header {
  display: flex; 
  justify-content: space-between;
  padding: 0 16px;
  border-bottom: 1px solid rgb(0, 0, 0, 0.12);
}

.shepherd-title {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  margin: 0;
}

.shepherd-cancel-icon {
  font-size: 26px;
  color: rgba(0, 0, 0, 0.54);
  background-color: #FFFFFF;
  border: none;
  cursor: pointer;
  padding: 0;
}

.iconeGuide {
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 50%;
  background: #4040A1;
  height: 20px;
  width: 20px;
  color: #FFFFFF;
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  margin-right: 10px;
  font-weight: 600;
}

.shepherd-text {
  margin: 15px 16px 0;
}

.textGuide {
  color: rgba(10, 90, 81, 1);
  font-weight: 500;
}

.shepherd-footer {
  width: 264px;
  height: 58px;
  display: flex;
  justify-content: flex-end;
}

.shepherd-button-primary {
  background-color: #FFFFFF;
  border: none;
  color: rgba(43, 43, 116, 1);
  font-weight: 600;
  font-size: 15px;
  line-height: 26px;
  width: fit-content;
}

.tour-home-passo1-material-complementar {
  width: fit-content;
  line-height: 36px;
}

.tour-consumo-materiais-aula-passo-1, .tour-consumo-materiais-disciplina-passo-2 {
  display: flex;
  align-items: center;
  width: fit-content;
}

.shepherd-button-secondary {
  background-color: #FFFFFF;
  border: none;
  color: rgba(0, 0, 0, 0.54);
  font-weight: 600;
  font-size: 15px;
  line-height: 26px;
  width: fit-content;
}

.shepherd-modal-overlay-container {
  height: 0;
  left: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: all .3s ease-out,height 0ms .3s,opacity .3s 0ms;
  width: 100vw;
  z-index: 9997;
}

.shepherd-modal-overlay-container.shepherd-modal-is-visible {
  height: 100vh;
  opacity: .5;
  transform: translateZ(0);
  transition: all .3s ease-out,height 0s 0s,opacity .3s 0s;
}

.shepherd-element {
  background:#fff;
  border-radius:5px;
  box-shadow:0 1px 4px rgba(0,0,0,.2);
  max-width:400px;
  opacity:0;
  outline:none;
  transition:opacity .3s,visibility .3s;
  visibility:hidden;
  z-index:9999;
  margin: 7px !important;
}

.shepherd-enabled.shepherd-element {
  opacity:1;
  visibility:visible;
}

.MuiMenu-paper {
  top: 61px !important;
}
